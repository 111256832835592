.hr-border {
    overflow: initial;
}

.hr-border:after {
    content: "";
    width: 15px;
    height: 7px;
    background-color: brown;
    opacity: .25;
    display: block;
}
hr {
    color: inherit;
    border: 0;
    border-top: 1px solid #f0f0f0;
}

  /*navigation-blog-contariner*/
  /*en computadoras position fixed y marginTop 300px*/
    /*en celulares position sticky y top 20px*/
    /*en tablets position sticky y top 20px*/

            
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          .navigation-blog-container {
              position: sticky;
              top: 20px;
          }
      }
      @media screen and (min-width: 1025px) {
          .navigation-blog-container {
              position: fixed;
              margin-top: 300px;
          }
      }